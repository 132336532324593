import axios from '@axios'

// interface ProductConservationInterface {
//     id: string;
//     name: string;
// }

// ProductConservation Router list
// GET    /product/conservations             Retrieves the collection of ProductConservation resources.
// GET    /product/conservations/{id}        Retrieves a ProductConservation resource.

// URL connection
//  8060 -> symfony API
//  8062 -> @fake-db
const API_BASE_MODULE = process.env.VUE_APP_API_BASE

// const API_BASE_MODULE = '' // @fake-db
// const API_BASE_MODULE = undefined // Take default BaseApiService

// class ProductConservation extends BaseApiService { constructor() { super(axios) }
class ProductConservation {
  axiosIns = axios

  API_BASE = API_BASE_MODULE !== undefined ? API_BASE_MODULE : this.baseUrl

  fetchProductConservations(queryParams) {
    // console.log(`${this.API_BASE}/product/conservations`)
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}/product/conservations`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchProductConservation({ id }) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}/product/conservations/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

// Exportamos useProductConservation (Antes se exportaba la clase y se definia un fichero nuevo para exportar la instancia)
const useProductConservation = new ProductConservation()
export default useProductConservation
