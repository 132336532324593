<template>
  <v-card>
    <!-- Top Action: Navigate to Marketplace/Campaign -->
    <div
      v-if="menuItemTopAction"
      class="pa-5"
    >
      <v-list-item
        link
        :to="menuItemTopAction.route"
      >
        <v-btn
          outlined
          block
          color="primary"
        >
          <v-icon size="22">
            {{ menuItemTopAction.icon }}
          </v-icon>
          <span class="ml-2">{{ menuItemTopAction.title }}</span>
        </v-btn>
      </v-list-item>
    </div>
    <v-card-title class="d-flex align-center align-content-between">
      <span>{{ $t('ProductFilters.title') }}</span>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        color="secundary"
        @click="clearFilters"
      >
        Clear
      </v-btn>
    </v-card-title>
    <v-row class="px-2 ma-0">
      <v-col
        v-if="(isAdminView && supplierFilterOptions !== undefined)"
        cols="12"
        :sm="smCols"
      >
        <v-select
          v-model="supplierFilterLocal"
          :label="$t('ProductFilters.supplier')"
          :items="supplierFilterOptions"
          item-text="user.firstName"
          return-object
          outlined
          clearable
          dense
          chips
          small-chips
          hide-details
          color="primary"
          @input="(item) => $emit('update:supplierFilter', item)"
        ></v-select>
      </v-col>

      <v-col
        v-if="(isAdminView && demanderFilterOptions !== undefined)"
        cols="12"
        :sm="smCols"
      >
        <v-select
          v-model="demanderFilterLocal"
          :label="$t('ProductFilters.demander')"
          :items="demanderFilterOptions"
          item-text="user.firstName"
          return-object
          outlined
          clearable
          dense
          chips
          small-chips
          hide-details
          color="primary"
          @input="(item) => $emit('update:demanderFilter', item)"
        ></v-select>
      </v-col>

      <v-col
        v-if="statusFilterOptions.length > 0"
        cols="12"
        :sm="smCols"
      >
        <!-- Return object(value: String, text: String) -->
        <!-- item-value="value" -->
        <v-select
          v-model="statusFilterLocal"
          :label="$t('ProductFilters.status')"
          :items="statusFilterOptions"
          item-text="text"
          return-object
          outlined
          clearable
          dense
          chips
          small-chips
          hide-details
          color="primary"
          @input="(item) => $emit('update:statusFilter', item)"
        ></v-select>
      </v-col>

      <!-- Optional: ProductDemander Flags -->
      <v-col
        v-if="(isMarketplaceAvailableFilterEnabled !== undefined || isCampaignReceiveAvailableFilterEnabled !== undefined)"
        cols="12"
        :sm="smCols"
      >
        <div
          v-if="(isMarketplaceAvailableFilterEnabled !== undefined)"
          class="d-flex align-center"
        >
          <v-checkbox
            v-model="isMarketplaceAvailableFilterEnabledLocal"
            hide-details
            class="shrink mr-2"
            @change="(event) => $emit('update:isMarketplaceAvailableFilterEnabled', event)"
          ></v-checkbox>
          <v-switch
            v-model="isMarketplaceAvailableFilterLocal"
            label="Marketplace ?"
            inset
            hide-details
            :disabled="!isMarketplaceAvailableFilterEnabledLocal"
            @change="(event) => $emit('update:isMarketplaceAvailableFilter', event)"
          ></v-switch>
        </div>

        <div
          v-if="(isCampaignReceiveAvailableFilterEnabled !== undefined)"
          class="d-flex align-center"
        >
          <v-checkbox
            v-model="isCampaignReceiveAvailableFilterEnabledLocal"
            hide-details
            class="shrink mr-2"
            @change="(event) => $emit('update:isCampaignReceiveAvailableFilterEnabled', event)"
          ></v-checkbox>
          <v-switch
            v-model="isCampaignReceiveAvailableFilterLocal"
            label="Campaign Receive?"
            inset
            hide-details
            :disabled="!isCampaignReceiveAvailableFilterEnabledLocal"
            @change="(event) => $emit('update:isCampaignReceiveAvailableFilter', event)"
          ></v-switch>
        </div>
      </v-col>

      <v-col
        cols="12"
        :sm="smCols"
      >
        <v-select
          v-model="productCategoryFilterLocal"
          :label="$t('ProductFilters.product_category')"
          :items="productCategoryFilterOptions"
          item-text="name"
          return-object
          outlined
          clearable
          dense
          hide-details
          @input="(item) => $emit('update:productCategoryFilter', item)"
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        :sm="smCols"
      >
        <v-select
          v-model="productFilterLocal"
          :label="$t('ProductFilters.product')"
          :items="productFilterOptions"
          item-text="name"
          return-object
          outlined
          dense
          clearable
          hide-details
          @input="(item) => $emit('update:productFilter', item)"
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        :sm="smCols"
      >
        <v-select
          v-model="productConservationFilterLocal"
          :label="$t('ProductFilters.product_conservation')"
          :items="productConservationFilterOptions"
          item-text="name"
          return-object
          outlined
          dense
          clearable
          hide-details
          @input="(item) => $emit('update:productConservationFilter', item)"
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        :sm="smCols"
      >
        <v-select
          v-model="productPresentationFilterLocal"
          :label="$t('ProductFilters.product_presentation')"
          :items="productPresentationFilterOptions"
          item-text="name"
          return-object
          outlined
          dense
          clearable
          hide-details
          @input="(item) => $emit('update:productPresentationFilter', item)"
        ></v-select>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

// Components
import useRouterPath from '@/router/useRouterPath'
import useAppConfigUser from '@/services/app/useAppConfigUser'

export default {
  props: {
    // Cols control (TODO - Se puede usar FLEX o GRID) Ver Snippet
    smCols: {
      type: String,
      default: '3',
    },

    isAdminView: {
      type: Boolean,
      default: false,
    },

    countFiltering: {
      type: Number,
      default: null,
    },

    // Filter: User (Admin view)
    supplierFilter: {
      type: Object,
      required: false,
      default: null,
    },
    supplierFilterOptions: {
      type: Array,
      required: false,
      default: () => undefined, // Solo queremos que se visualize si se inicializa desde el padre
    },
    demanderFilter: {
      type: Object,
      required: false,
      default: null,
    },
    demanderFilterOptions: {
      type: Array,
      required: false,
      default: () => undefined, // Solo queremos que se visualize si se inicializa desde el padre
    },

    // Filter ProductSupplier flags
    isMarketplaceAvailableFilterEnabled: {
      type: Boolean,
      default: undefined, // Solo queremos que se visualize si se inicializa desde el padre
    },
    isMarketplaceAvailableFilter: {
      type: Boolean,
      default: true,
    },
    isCampaignReceiveAvailableFilterEnabled: {
      type: Boolean,
      default: undefined, // Solo queremos que se visualize si se inicializa desde el padre
    },
    isCampaignReceiveAvailableFilter: {
      type: Boolean,
      default: true,
    },

    // Filter: Status
    statusFilter: {
      type: Object,
      required: false,
      default: null,
    },
    statusFilterOptions: {
      type: Array,
      required: false,
      default: () => [],
    },

    // Filter: Category
    productCategoryFilter: {
      type: Object,
      required: false,
      default: null,
    },
    productCategoryFilterOptions: {
      type: Array,
      required: true,
    },

    // Filter: Product
    productFilter: {
      type: Object,
      required: false,
      default: null,
    },
    productFilterOptions: {
      type: Array,
      required: true,
    },

    // Filter: ProductConservation
    productConservationFilter: {
      type: Object,
      required: false,
      default: null,
    },
    productConservationFilterOptions: {
      type: Array,
      required: true,
    },

    // Filter: ProductPresentation
    productPresentationFilter: {
      type: Object,
      required: false,
      default: null,
    },
    productPresentationFilterOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { isSeller } = useAppConfigUser()
    const { menuItemCampaignPublished, menuItemMarketplace } = useRouterPath()

    // Set Action: Vendedor -> Campaign | Comprador -> ProductSupplierList
    const menuItemTopAction = ref(null)
    if (!props.isAdminView) {
      if (isSeller.value) {
        menuItemTopAction.value = menuItemCampaignPublished
      } else {
        menuItemTopAction.value = menuItemMarketplace
      }
    }

    // ProductDemander Flags
    const isMarketplaceAvailableFilterEnabledLocal = ref(props.isMarketplaceAvailableFilterEnabled)
    const isMarketplaceAvailableFilterLocal = ref(props.isCampaignReceiveAvailableFilter)
    const isCampaignReceiveAvailableFilterEnabledLocal = ref(props.isCampaignReceiveAvailableFilterEnabled)
    const isCampaignReceiveAvailableFilterLocal = ref(props.isCampaignReceiveAvailableFilter)

    // ProductSupplier Status
    const statusFilterLocal = ref(JSON.parse(JSON.stringify(props.statusFilter)))

    const supplierFilterLocal = ref(JSON.parse(JSON.stringify(props.supplierFilter)))
    const demanderFilterLocal = ref(JSON.parse(JSON.stringify(props.demanderFilter)))

    const productCategoryFilterLocal = ref(JSON.parse(JSON.stringify(props.productCategoryFilter)))
    const productFilterLocal = ref(JSON.parse(JSON.stringify(props.productFilter)))
    const productConservationFilterLocal = ref(JSON.parse(JSON.stringify(props.productConservationFilter)))
    const productPresentationFilterLocal = ref(JSON.parse(JSON.stringify(props.productPresentationFilter)))

    const calculateCountFiltering = () => {
      const count =
        (statusFilterLocal.value ? 1 : 0) +
        (isMarketplaceAvailableFilterEnabledLocal.value ? 1 : 0) +
        (isCampaignReceiveAvailableFilterEnabledLocal.value ? 1 : 0) +
        (supplierFilterLocal.value ? 1 : 0) +
        (demanderFilterLocal.value ? 1 : 0) +
        (productCategoryFilterLocal.value ? 1 : 0) +
        (productFilterLocal.value ? 1 : 0) +
        (productConservationFilterLocal.value ? 1 : 0) +
        (productPresentationFilterLocal.value ? 1 : 0)

      return count
    }

    // Este watch solo es necesario si el filtro se duplica (Por ejemplo crear 2 componentes)
    watch(
      [
        () => props.supplierFilter,
        () => props.demanderFilter,
        () => props.isMarketplaceAvailableFilter,
        () => props.isCampaignReceiveAvailableFilter,
        () => props.statusFilter,
        () => props.productCategoryFilter,
        () => props.productFilter,
        () => props.productConservationFilter,
        () => props.productPresentationFilter,
      ],
      () => {
        isMarketplaceAvailableFilterLocal.value = JSON.parse(JSON.stringify(props.isMarketplaceAvailableFilter))
        isCampaignReceiveAvailableFilterLocal.value = JSON.parse(JSON.stringify(props.isCampaignReceiveAvailableFilter))
        statusFilterLocal.value = JSON.parse(JSON.stringify(props.statusFilter))

        supplierFilterLocal.value = JSON.parse(JSON.stringify(props.supplierFilter))
        demanderFilterLocal.value = JSON.parse(JSON.stringify(props.demanderFilter))
        productCategoryFilterLocal.value = JSON.parse(JSON.stringify(props.productCategoryFilter))
        productFilterLocal.value = JSON.parse(JSON.stringify(props.productFilter))
        productConservationFilterLocal.value = JSON.parse(JSON.stringify(props.productConservationFilter))
        productPresentationFilterLocal.value = JSON.parse(JSON.stringify(props.productPresentationFilter))

        emit('update:countFiltering', calculateCountFiltering())
      },
    )

    // Clear all filters
    const clearFilters = () => {
      supplierFilterLocal.value = null
      emit('update:supplierFilter', supplierFilterLocal.value)

      demanderFilterLocal.value = null
      emit('update:demanderFilter', demanderFilterLocal.value)

      statusFilterLocal.value = null
      emit('update:statusFilter', statusFilterLocal.value)

      isMarketplaceAvailableFilterEnabledLocal.value = false
      isMarketplaceAvailableFilterLocal.value = false
      emit('update:isMarketplaceAvailableFilterEnabled', isMarketplaceAvailableFilterEnabledLocal.value)
      emit('update:isMarketplaceAvailableFilter', isMarketplaceAvailableFilterLocal.value)

      isCampaignReceiveAvailableFilterEnabledLocal.value = false
      isCampaignReceiveAvailableFilterLocal.value = false
      emit('update:isCampaignReceiveAvailableFilterEnabled', isCampaignReceiveAvailableFilterEnabledLocal.value)
      emit('update:isCampaignReceiveAvailableFilter', isCampaignReceiveAvailableFilterLocal.value)

      productCategoryFilterLocal.value = null
      emit('update:productCategoryFilter', productCategoryFilterLocal.value)

      productFilterLocal.value = null
      emit('update:productFilter', productFilterLocal.value)

      productConservationFilterLocal.value = null
      emit('update:productConservationFilter', productConservationFilterLocal.value)

      productPresentationFilterLocal.value = null
      emit('update:productPresentationFilter', productPresentationFilterLocal.value)
    }

    return {
      menuItemTopAction,

      // Three state filter
      isMarketplaceAvailableFilterEnabledLocal,
      isMarketplaceAvailableFilterLocal,
      isCampaignReceiveAvailableFilterEnabledLocal,
      isCampaignReceiveAvailableFilterLocal,

      // Data
      // isMarketplaceAvailableFilterEnabled,
      // isCampaignReceiveAvailableFilterEnabled,
      supplierFilterLocal,
      demanderFilterLocal,
      statusFilterLocal,
      productCategoryFilterLocal,
      productFilterLocal,
      productConservationFilterLocal,
      productPresentationFilterLocal,

      // Events
      clearFilters,
    }
  },
}
</script>

<!-- <style lang="scss" scoped>
  @import '~vuetify/src/styles/styles.sass';

  /*
    These are the selectors controlling the selected item
    in v-autocomplete and v-select
  */
  .v-select__selection,
  .v-select__selection--comma,
  .v-select.v-text-field input {
    color: var(--v-primary-base) !important;
  }

  // .v-select__selection,
  // .v-chip {
  //   background: var(--v-primary-base) !important;
  // }
</style> -->
