import axios from '@axios'

// interface ProductCategoryInterface {
//     id: string;
//     name: string;
//     description: string;
//     image: string;
// }

// ProductCategory Router list
// GET    /product/categories             Retrieves the collection of ProductCategory resources.
// GET    /product/categories/{id}        Retrieves a ProductCategory resource.
// POST   /product/categories/media/{id}  Upload a ProductCategory image. (multipart/form-data only in POST or GET)

// URL connection
//  8060 -> symfony API
//  8062 -> @fake-db
const API_BASE_MODULE = process.env.VUE_APP_API_BASE

// const API_BASE_MODULE = '' // @fake-db
// const API_BASE_MODULE = undefined // Take default BaseApiService

// class Product extends BaseApiService { constructor() { super(axios) }
class ProductCategory {
  axiosIns = axios

  API_BASE = API_BASE_MODULE !== undefined ? API_BASE_MODULE : this.baseUrl

  fetchProductCategories(queryParams) {
    // console.log(`${this.API_BASE}/product/categories`)
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}/product/categories`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchProductCategory({ id }) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}/product/categories/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // Ver CompanyService class
  updateProductCategoryMedia({ id }, formData) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}/product/categories/media/${id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

// Exportamos useProductCategory (Antes se exportaba la clase y se definia un fichero nuevo para exportar la instancia)
const useProductCategory = new ProductCategory()
export default useProductCategory
