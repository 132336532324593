import { ref } from '@vue/composition-api'

// Services
import useProduct from '@/services/api/modules/product'
import useProductCategory from '@/services/api/modules/productCategory'
import useProductPresentation from '@/services/api/modules/productPresentation'
import useProductConservation from '@/services/api/modules/productConservation'

export default function useProductMasterRemoteData() {
  // Before: Using store
  // const fetchProducts = (...args) => store.dispatch('product/fetchProducts', ...args)
  // const fetchProductCategories = (...args) => store.dispatch('product/fetchProductCategories', ...args)
  // const fetchProductConservations = (...args) => store.dispatch('product/fetchProductConservations', ...args)
  // const fetchProductPresentations = (...args) => store.dispatch('product/fetchProductPresentations', ...args)

  // Now: Using API Service
  // ProductCategories
  const productCategories = ref([])
  const fetchProductCategories = (...args) => useProductCategory.fetchProductCategories(...args)

  // Products
  const products = ref([])
  const fetchProducts = (...args) => useProduct.fetchProducts(...args)

  // ProductConservations
  const productConservations = ref([])
  const fetchProductConservations = (...args) => useProductConservation.fetchProductConservations(...args)

  // ProductPresentations
  const productPresentations = ref([])
  const fetchProductPresentations = (...args) => useProductPresentation.fetchProductPresentations(...args)

  return {
    productCategories,
    fetchProductCategories,

    products,
    fetchProducts,

    productConservations,
    fetchProductConservations,

    productPresentations,
    fetchProductPresentations,
  }
}
