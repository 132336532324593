import axios from '@axios'

// Especie
// interface ProductInterface {
//     id: string;
//     value: string;
//     name: string;
//     productCategory: any
//   }

// Product Router list
// GET    /products             Retrieves the collection of Product resources.
// GET    /products/{id}        Retrieves a Product resource.

// URL connection
//  8060 -> symfony API
//  8062 -> @fake-db
const API_BASE_MODULE = process.env.VUE_APP_API_BASE

// const API_BASE_MODULE = '' // @fake-db
// const API_BASE_MODULE = undefined // Take default BaseApiService

// class Product extends BaseApiService { constructor() { super(axios) }
class Product {
  axiosIns = axios

  API_BASE = API_BASE_MODULE !== undefined ? API_BASE_MODULE : this.baseUrl

  fetchProducts(queryParams) {
    // console.log(`${this.API_BASE}/products`)
    // const url = `${this.API_BASE}/products?q=&perPage=10&page=1&sortBy=id&sortDesc=true&productCategoryId=d1734cbc-4d58-11ec-b119-1db845312732`
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}/products`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchProduct({ id }) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}/products/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

// Exportamos useProduct
const useProduct = new Product()
export default useProduct
