import axios from '@axios'

// interface ProductPresentationInterface {
//     id: string;
//     name: string;
// }

// ProductPresentation Router list
// GET    /product/presentations             Retrieves the collection of ProductPresentation resources.
// GET    /product/presentations/{id}        Retrieves a ProductPresentation resource.

// URL connection
//  8060 -> symfony API
//  8062 -> @fake-db
const API_BASE_MODULE = process.env.VUE_APP_API_BASE

// const API_BASE_MODULE = '' // @fake-db
// const API_BASE_MODULE = undefined // Take default BaseApiService

// class ProductPresentation extends BaseApiService { constructor() { super(axios) }
class ProductPresentation {
  axiosIns = axios

  API_BASE = API_BASE_MODULE !== undefined ? API_BASE_MODULE : this.baseUrl

  fetchProductPresentations(queryParams) {
    // console.log(`${this.API_BASE}/product/presentations`)
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}/product/presentations`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchProductPresentation({ id }) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}/product/presentations/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

// Exportamos useProductPresentation (Antes se exportaba la clase y se definia un fichero nuevo para exportar la instancia)
const useProductPresentation = new ProductPresentation()
export default useProductPresentation
